import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import '../style/components/backtohome.css';

const BackToHome = () => {
  return (
    <Link to="/" className="back-to-home">
      <FontAwesomeIcon icon={faArrowLeftLong} className="fa-beat"/>
    </Link>
  );
};

export default BackToHome;

