import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import '../style/components/contactme.css';

function Contact() {
    const form = useRef();  // Utilisation de useRef pour référencer le formulaire

    const sendEmail = (e) => {
        e.preventDefault();  // Empêche le rechargement de la page lors de la soumission

        emailjs.sendForm('service_iewk0gr', 'template_o9qvvvk', form.current, 'HmHlHRWJtDfEvrGcF')
            .then((result) => {
                console.log(result.text);
                alert('Message envoyé avec succès !');
                form.current.reset();  // Réinitialise le formulaire après l'envoi
            }, (error) => {
                console.log(error.text);
                alert('Erreur lors de l\'envoi du message.');
            });
    };

    return (
        <section id="contactme">
            <h2>Contact</h2>
            <p>Vous avez un projet ? Discutons-en !</p>
            <form ref={form} onSubmit={sendEmail}>  {/* Utilisation de ref et onSubmit */}
                <label htmlFor="name">Nom</label>
                <input type="text" name="name" id="name" required />
                
                <label htmlFor="email">Email</label>
                <input type="email" name="email" id="email" required />
                
                <label htmlFor="message">Message</label>
                <textarea name="message" id="message" cols="30" rows="10" required></textarea>
                
                <input type="submit" value="Envoyer" />
            </form>
        </section>
    );
}

export default Contact;

