import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import '../style/components/slideshow.css'
import { useState } from 'react'

function Slideshow({ pictures }) {

  const [index, setIndex] = useState(0);


  let totalLength = pictures.length;
  

  const handlePrevious = () => {
    const newIndex = index - 1;

    setIndex(newIndex < 0 ? totalLength - 1 : newIndex);
  };

  const handleNext = () => {
    const newIndex = index + 1;

    setIndex(newIndex >= totalLength ? 0 : newIndex);

  };

  return (
    <div
      className="slideshow_container"
      style={{
        backgroundImage: `url(${pictures[index]})`,
      }}
    >

      <FontAwesomeIcon
        icon={faChevronLeft}
        className="slideshow_icons left"
        id="left"
        onClick={handlePrevious}
        style={{ float: 'left' }}
      />

      <FontAwesomeIcon
        icon={faChevronRight}
        className="slideshow_icons right"
        id="right"
        onClick={handleNext}
        style={{ float: 'right' }}
      />

      <span className="slideshow_counter">
        {index + 1} / {pictures.length}
      </span>
    </div>
  );
}

export default Slideshow;
