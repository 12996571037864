import React from 'react';
import "../style/components/aboutme.css"


function About() {
    return (
      <div id="aboutme">
        <img src="https://res.cloudinary.com/dayzkpcwn/image/upload/f_auto,q_auto/v1725825090/logowhite_rwbjuk_c_fill_w_360_h_360_zx2mzh.png" alt="Logo blanc de mon portfolio" className="logo_white" />
        <div className="container">
          <h3 className="about_title">À propos de moi</h3>
          <p className="desc_about">En tant que développeur web passionné, je me spécialise dans la création de sites modernes, réactifs et performants. Mon parcours professionnel a été enrichi par une formation approfondie en développement web avec OpenClassrooms. Cette formation m'a permis d'acquérir des compétences dans divers aspects du développement web. Avec 24 ans d'expérience de vie, j'apporte une perspective jeune et dynamique à mes projets, en restant constamment à l'affût des dernières tendances et technologies du web.</p>
        </div>
      </div>
    );
  }
  
  export default About;