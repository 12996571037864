import React from 'react';
import "../style/components/skills.css";

function Skills() {
    return (
        <div id="skills">
            <h3 className="competence">Mes compétences</h3>
            <div className="skillsicons">
                <ul className="iconslist">
                    <li className="icons"><img src="https://res.cloudinary.com/dayzkpcwn/image/upload/f_auto,q_auto/v1725822594/367627_html_html5_icon_dc90nx.png" alt="Logo html" className="logo_html" /></li>
                    <li className="icons"><img src="https://res.cloudinary.com/dayzkpcwn/image/upload/f_auto,q_auto/v1725822595/282802_javascript_js_icon_alvee6.png" alt="Logo javascript" className="logo_JS" /></li>
                    <li className="icons"><img src="https://res.cloudinary.com/dayzkpcwn/image/upload/f_auto,q_auto/v1725824339/9117997_css_fill_icon_wxtbbf.png" alt="Logo css" className="logo_css" /></li>
                    <li className="icons"><img src="https://res.cloudinary.com/dayzkpcwn/image/upload/f_auto,q_auto/v1725822591/4691292_react_native_react_icon_nr5tox.png" alt="Logo react" className="logo_react" /></li>
                    <li className="icons"><img src="https://res.cloudinary.com/dayzkpcwn/image/upload/f_auto,q_auto/v1725822588/8666425_sass_icon_aai5tl.png" alt="Logo sass" className="logo_sass" /></li>
                    <li className="icons"><img src="https://res.cloudinary.com/dayzkpcwn/image/upload/f_auto,q_auto/v1725822585/9035101_logo_nodejs_icon_ott5sr.png" alt="Logo node" className="logo_node" /></li>
                    <li className="icons"><img src="https://res.cloudinary.com/dayzkpcwn/image/upload/f_auto,q_auto/v1725822585/expressjs_logo_icon_169185_lq7ujp.png" alt="Logo express" className="logo_express" /></li>
                    <li className="icons"><img src="https://res.cloudinary.com/dayzkpcwn/image/upload/f_auto,q_auto/v1725822592/4691284_mongodb_icon_tbr3kh.png" alt="Logo mongodb" className="logo_mongodb" /></li>
                </ul>
            </div>
        </div>
    );
}

export default Skills;
