import React from 'react';
import Header from '../layout/header';

const WithHeader = ({ children, showHeader = true }) => {
  return (
    <div>
      {showHeader && <Header />}
      {children}
    </div>
  );
};

export default WithHeader;
