import React from 'react';
import About from '../components/aboutme';
import Entrance from '../components/entrance';
import Skills from '../components/skills'
import Projectcards from '../components/project_cards';
import Contactme from '../components/contactme';
import WithHeader from '../components/withHeader';

function Portfolio() {
  return (
    <div className="principal">
      <WithHeader>
      <Entrance />
      <About />
      <Projectcards />
      <Skills />
      <Contactme />
      </WithHeader>
    </div>
  );
}

export default Portfolio;
