import React from 'react';
import "../style/components/entrance.css";


function Entrance () {
    return(
    <div id="banner">
        <img src="https://res.cloudinary.com/dayzkpcwn/image/upload/w_648,h_648,f_auto/v1725858492/code_noirblanc_s1iezg.png" alt="pc" className="image_BW" />
        <div className="description">
        <h1>TOM <br />WALKER</h1>
            <h2 className="title_entrance">Développeur Web </h2>
                <p className="desc_entrance">Passionné par la création de sites web élégants et réactifs,je mets tout en œuvre pour offrir des expériences en ligne modernes et engageantes.</p>
        <div className="button">
        <a href="https://github.com/Txmwalker" className="github-button" target="_blank" rel="noopener noreferrer">
        <img src="https://res.cloudinary.com/dayzkpcwn/image/upload/v1725916433/8666148_github_square_icon_b6xsyk.png" alt="mon github" className="mygit_button"></img></a>
        <a href="https://www.linkedin.com/in/tom-walker-045471327/" className="link-button" target="_blank" rel="noopener noreferrer">
        <img src="https://res.cloudinary.com/dayzkpcwn/image/upload/w_56,h_56/v1725916425/104493_linkedin_icon_u1qnej.png" alt="mon linkedin" className="mylink_logo"></img></a>
        </div>
    </div> 
    </div>         
    )
}

export default Entrance;