import BackToHome from '../components/backtohome.jsx'
import Slideshow from '../components/slideshow.jsx';
import Collapse from '../components/collapse.jsx';
import WithHeader from '../components/withHeader';
import '../style/page/projects.css';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

function Projects() {
    const { id } = useParams();
    console.log(id);

    const [projet, setProjet] = useState(null);
    const [loading, setLoading] = useState(true); // Ajout d'un état de chargement

    useEffect(() => {
        fetch('/data.json')
            .then((response) => response.json())
            .then((data) => {
                console.log(data);

                let founded = data.find(item => item.id === id);
                setProjet(founded);
                setLoading(false); // Fin du chargement
            })
            .catch((error) => {
                console.log(error.message);
                setLoading(false); // Fin du chargement en cas d'erreur
            });
    }, [id]);

    if (loading) {
        return <div>Loading...</div>; // Afficher un message de chargement
    }

    if (!projet) {
        return <div>Project not found</div>; // Afficher un message si le projet n'est pas trouvé
    }

    return (
        <WithHeader showHeader={false}>
        <BackToHome />
        <div id="projects">
            <div className="projet_main-wrapper">
                <Slideshow pictures={projet.pictures} />

                <div className="projet_container">

                <div className="icon-container">
                    {projet.competences.map((iconUrl, index) => (
                        <img key={index} src={iconUrl} alt={`Icon ${index}`} className="project-icon" />
                         ))}
                    </div>

                    <h2 className="projet_title">{projet.title}</h2>

                    {projet.github && (
                        <a href={projet.github} target="_blank" rel="noopener noreferrer">
                        <img src="https://res.cloudinary.com/dayzkpcwn/image/upload/f_auto,q_auto/v1725844757/764894_github_media_social_square_icon_ucxjs2.png" alt="GitHub Logo" className="github-logo" />
                     </a>
                    )}
                </div>

                <div className="collapse_section" id="collapse_project">
                    <Collapse
                        title="Description"
                        content={projet.description}
                        id="Project_description"
                    />
                    <Collapse
                        title="Mes missions"
                        content={projet.solution}
                        id="Project_solution"
                    />
                </div>
            </div>
        </div>
        </WithHeader>
    );
}

export default Projects;
