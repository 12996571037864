import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Footer from './layout/footer'; 
import Portofolio from './page/portofolio'; 
import Projects from './page/projects';

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Portofolio />} />
        <Route path="/projects/:id" element={<Projects />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
