import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import '../style/components/collapse.css';

function Collapse({ title, content }) {
  

  console.log(content);
  
  const [isOpen, setIsOpen] = useState(false);
  
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <section className="collapse">
      
      <div
        className="headercollapse"
        onClick={toggleCollapse}
        aria-expanded={isOpen}
        aria-controls="collapse-content"
      >
        
        {title}
        
        <FontAwesomeIcon
          icon={faChevronUp}
          style={{
            transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
            transition: 'all 0.3s ease',
          }}
        />
        
      </div>
      <div id="collapse-content" className={isOpen ? 'content open' : 'content'}>
        
        {content && Array.isArray(content) ? (
          <ul>
            {content.map((element, index) => (
              <li key={index} style={{ listStyle: 'none' }}>
                {element}
              </li>
            ))}
          </ul>
        ) : (
          content
        )}
      </div>
    </section>
  );
}

export default Collapse;
