import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../style/components/project_cards.css';

function Project_cards() {
 

  const [projets, setProjets] = useState([]);
  // Utilisation de useState pour déclarer une variable d'état "locations" et une fonction "setLocations" pour la mettre à jour.
  // Initialement, "locations" est un tableau vide.

  useEffect(() => {
    // Utilisation de useEffect pour effectuer une requête fetch.

    fetch('data.json')
      .then((response) => response.json())
      
      .then((data) => {
        setProjets(data);
      })
      .catch((error) => {
        console.log(error.message);
        
      });
  }, []);

  return (
    <div id ="myprojects">
      <h3 className ="myproject_title">Mes projets</h3>
    <ul className="project_cards">
      
      {projets.map((projet) => (
        <li
          key={projet.id}
          className="project_cards-card"
         
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(255,255,255,1) 0%,rgba(0,0,0,0.5) 85%, rgba(0,0,0,1) 100%), url(${projet.cover})`,
          }}
        >
          <Link
            to={`/projects/${projet.id}`}
            className="projects_cards-link"
            style={{ height: '100%', width: '100%' }}
          >
            <h2 className="projects_cards-title">{projet.title}</h2>
          </Link>
        </li>
      ))}
    </ul>
    </div>
  );
}

export default Project_cards;
           


