import React from 'react';
import '../style/layout/footer.css';

function Footer() {

  return (
    <footer>

      <div className='footer_div'>

        <img
          src="https://res.cloudinary.com/dayzkpcwn/image/upload/f_auto,q_auto/v1725825090/logowhite_rwbjuk_c_fill_w_360_h_360_zx2mzh.png"
          alt="Logo Kasa"
          className="footer_logo"
        />

        <p className='desc_footer'> &copy; 2024 TomWalker. All rights reserved</p>
      </div>
    </footer>
  );
}

export default Footer;