import React from 'react';
import "../style/layout/header.css"

function Header() {
  return (
    <nav className="header">
        <img src="https://res.cloudinary.com/dayzkpcwn/image/upload/f_auto,q_auto/v1725824932/w_hmeilq_c_pad_w_144_h_144_j9bn8s.png" alt="Logo de mon portfolio" className="logo_TW" />
        <ul>
          <li><a href="#aboutme">À propos</a></li>
          <li><a href="#myprojects">Mes projets</a></li>
          <li><a href="#skills">Mes compétences</a></li>
          <li><a href="#contactme">Contactez-moi</a></li>
        </ul>
      </nav>
  );
}

export default Header;
